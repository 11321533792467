import axiosInstance from '../axiosInstance';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Button, InputGroup, Modal } from 'react-bootstrap';
import { FaEye, FaFileAlt } from 'react-icons/fa';

import { numberToWords } from '../Common/modules/EnNumToWord';
import { setTargetLoan } from '../Common/redux/systemLookups';
import EtCurrency from '../Common/modules/currency';

function LoanComp({ targetLoan, targetClient, successnotify, errornotify }) {
  const { serverIP } = useSelector(store => store.allsettings);
  const { data } = useSelector(store => store.customer);
  const dispatch = useDispatch();

  const [multiplier, setMultiplier] = useState('');
  const [totalInterestPayment, settotalInterestPayment] = useState('');
  const [queueTime, setqueueTime] = useState(0);

  const [lastReturnDate, setlastReturnDate] = useState('');
  const [totalDueForPeriod, settotalDueForPeriod] = useState('');
  const [bankstateent, setbankstateent] = useState('');

  const [file, setFile] = useState(null);
  const [viewObj, setViewObj] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    setMultiplier(targetLoan.multiplier);
    settotalInterestPayment(targetLoan.totalInterestPayment === 0 ? 0 : targetLoan.totalInterestPayment);
    setqueueTime(targetLoan.queueTime === 0 ? '' : targetLoan.queueTime);
    setlastReturnDate(targetLoan.expectedDisbursementDate);
    settotalDueForPeriod(targetLoan.totalDueForPeriod);
    setbankstateent(targetLoan.bankstateent);
  }, []);

  const saveChanges = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        multiplier,
        totalInterestPayment,
        queueTime,
        lastReturnDate,
        totalDueForPeriod,
        bankstateent,
        queueDate: addBusinessDays(queueTime)
      })
      .then(res => {
        successnotify('የብድር መረጃ ተስተካክሏል!');
        getLoan();
      })
      .catch(err => {
        console.log(err);
        errornotify();
      });
  };

  const getLoan = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${targetLoan.id}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  function addBusinessDays(daysToAdd) {
    let currentDate = new Date();
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        addedDays++;
      }
    }
    return currentDate.toISOString().split('T')[0];
  }

  function handleUpload(fileid) {
    console.log(file);
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/loan/loans/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        // fd = new FormData();
        successnotify();
        getLoan();
      })
      .catch(err => {
        errornotify();
        console.log(err);
      });
  }

  return (
    <div>
      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}
      <div className="row">
        <div className="col-sm-12">
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div> ሙሉ ስም: {' ' + targetClient.amDisplayName}</div>
              <div>External ID: {' ' + targetClient.entityExternalId}</div>
              <div> የአካውንት ቁጥር: {' ' + targetClient.entityAccountNo}</div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              ቁጠባ:
              {' ' + targetLoan?.totalSaving?.toLocaleString('am-ET', EtCurrency)}
              &nbsp; &nbsp;
              <b> {numberToWords(targetLoan?.r_totalSaving)} </b>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              {/* <ListGroup.Item className="d-flex justify-content-between align-items-start"> */}
              አክስዮን:
              {' ' + targetLoan?.totalShares?.toLocaleString('am-ET', EtCurrency)}
              &nbsp; &nbsp;
              <b> {numberToWords(targetLoan?.totalShares)} </b>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              የተፈቀድው የብድር መጠን : &nbsp;
              {targetLoan.approvedPrincipal?.toLocaleString('am-ET', EtCurrency)}
              &nbsp; &nbsp;
              <b>{numberToWords(targetLoan?.approvedPrincipal)}</b>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>

      <div className="bg-gray-500 rounded-xl py-2 px-3 mt-2 flex flex-row justify-between text-white">
        <div>Product: {targetLoan.loanProductName}</div>
        <div>Purpose: {targetLoan.loanPurposeName}</div>
      </div>

      {/* Container Start */}
      <div className="row">
        <div className="col-sm-8">
          <div
            style={{
              marginTop: 5,
              backgroundColor: 'white',
              borderRadius: 5
            }}
          >
            <div className="row" style={{ paddingTop: 5 }}>
              <div className="input-group">
                <span className="input-group-text"> የቁጠባ ማባዥ </span>
                <input
                  className="form-control"
                  type="number"
                  value={multiplier}
                  onChange={e => setMultiplier(Number(e.target.value))}
                  placeholder=" የቁጠባ ማባዥ "
                />
              </div>
            </div>
            <div className="row" style={{ paddingTop: 5 }}>
              <div className="input-group">
                <span className="input-group-text">ጠቅላላ የወለድ መጠን </span>
                <input
                  className="form-control"
                  type="number"
                  value={totalInterestPayment}
                  onChange={e => settotalInterestPayment(Number(e.target.value))}
                  placeholder="ጠቅላላ የወለድ መጠን "
                />
              </div>
            </div>

            <div className="input-group mb-1" style={{ paddingTop: 5 }}>
              <div className="input-group-prepend">
                <span className="input-group-text">የብድር ሂደት ለመጀመር የወረፋ ግዜ መጠበቅያ</span>
              </div>
              <input
                className="form-control"
                type="number"
                value={queueTime}
                onChange={e => {
                  setqueueTime(e.target.value);
                  console.log(e.target.value);
                }}
                placeholder=" የብድር ሂደት ለመጀመር የወረፋ ግዜ መጠበቅያ "
              />
              <div className="input-group-append">
                <span className="input-group-text">{targetLoan.queueDate}</span>
              </div>
            </div>

            <div className="row" style={{ paddingTop: 5 }}>
              <div className="input-group">
                <span className="input-group-text">ብድሩ ተመልሶ የሚያልቅበት ቀን </span>
                <input
                  className="form-control"
                  type="date"
                  value={lastReturnDate}
                  onChange={e => setlastReturnDate(e.target.value)}
                  placeholder="ብድሩ ተመልሶ የሚያልቅበት ቀን "
                />
              </div>
            </div>

            <div className="row" style={{ paddingTop: 10 }}>
              <div className="input-group">
                <span className="input-group-text"> ወርሀዊ ክፍያ </span>
                <input
                  className="form-control"
                  type="text"
                  value={totalDueForPeriod}
                  onChange={e => settotalDueForPeriod(e.target.value)}
                  placeholder="ወርሀዊ ክፍያ "
                />
              </div>
            </div>

            <div className="row" style={{ paddingTop: 10 }}>
              <div className="input-group">
                <span className="input-group-text"> ባንክ ስቴትመንት </span>
                <input
                  className="form-control"
                  type="text"
                  value={bankstateent}
                  onChange={e => setbankstateent(e.target.value)}
                  placeholder="ባንክ ስቴትመንት "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <ListGroup className="mt-2">
            <ListGroup.Item>{`ሰርቪስ ቻርጅ: ${targetLoan.flatServiceCharge?.toLocaleString()}`}</ListGroup.Item>
            <ListGroup.Item>{`ላይፍ ኢንሹራንስ: ${targetLoan.flatLifeInsurance?.toLocaleString()}`}</ListGroup.Item>
            <ListGroup.Item>{`የወለድ ብዜት: ${targetLoan.annualInterestRate?.toLocaleString()} %`}</ListGroup.Item>
            <ListGroup.Item>{`ጠቅላላ ወለድ: ${targetLoan.totalInterestPayment?.toLocaleString()}`}</ListGroup.Item>

            {targetLoan.isUploaded ? (
              <ListGroup.Item className="bg-green-600 text-white flex justify-between">
                <div>አፕሎድ ተደርጓል</div>
                {targetLoan.fileType === 'application/pdf' ? (
                  <a target="_blank" href={serverIP + targetLoan.fileUrl} rel="noopener noreferrer">
                    <FaFileAlt />
                  </a>
                ) : (
                  <FaEye
                    onClick={() => {
                      setViewObj(targetLoan);
                      setShow(true);
                      console.log('set show clicked');
                    }}
                  />
                )}

                {/* <div>አፕሎድ ተደርጓል</div>
                <FaEye /> */}
              </ListGroup.Item>
            ) : (
              <ListGroup.Item className="bg-red-300 text-white font-bold">ስሊፕ አፕሎድ አልተደረገም</ListGroup.Item>
            )}
            <InputGroup style={{ paddingTop: 0 }}>
              <input
                onChange={e => {
                  setFile(e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                type="file"
                className="form-control"
              />
              <Button variant="outline-primary" onClick={() => handleUpload(targetLoan.id)}>
                {!targetLoan.isUploaded ? <>ጫን</> : <>አስተካክል</>}
              </Button>
            </InputGroup>
          </ListGroup>
        </div>
      </div>
      {/* Container end  */}
      <div
        style={{
          paddingTop: 10,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {data.groups[0] === 'ROLE_OFFICER' ? (
          <Button className="btn-warning btn-sm" onClick={saveChanges}>
            አስተካክል
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default LoanComp;
