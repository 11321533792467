// CHARACTER - C1

function Character_A(loanFinanceItems) {
  const sum_category_one = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category == 9 ? obj.markValue : 0);
  }, 0);
  return sum_category_one;
}

function Character_B(loanFinanceItems) {
  const sum_category_one = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category == 9 ? obj.markValue : 0);
  }, 0);
  return sum_category_one;
}

function Character_C(loanFinanceItems) {
  const sum_category_one = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category == 9 ? obj.markValue : 0);
  }, 0);
  return sum_category_one;
}

// CAPITAL - C2

function Capital_A(FnItems) {
  return FnItems[25]?.markValue + FnItems[26]?.markValue + FnItems[27]?.markValue + FnItems[28]?.markValue;
}

function Capital_B(FnItems) {
  return FnItems[29]?.markValue + FnItems[30]?.markValue + FnItems[31]?.markValue;
}

function Capital_C(FnItems) {
  return FnItems[32]?.markValue + FnItems[33]?.markValue;
}

// Ability To Pay - C3

function Ability_to_pay_A(FnItems) {
  return (
    FnItems[17]?.markValue +
    FnItems[18]?.markValue +
    FnItems[19]?.markValue +
    FnItems[20]?.markValue +
    FnItems[21]?.markValue +
    FnItems[22]?.markValue +
    FnItems[23]?.markValue +
    FnItems[24]?.markValue
  );
}

function Ability_to_pay_B(FnItems) {
  return FnItems[17]?.markValue + FnItems[18]?.markValue;
}

// CONDITIONS - C4

function Conditions_A(loanFinanceItems) {
  const sum_category_one = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category == 9 ? obj.markValue : 0);
  }, 0);
  return sum_category_one;
}

// COLLATERAL - C5

function Collateral_A(loanFinanceItems) {
  const sum_category_one = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category == 9 ? obj.markValue : 0);
  }, 0);
  return sum_category_one;
}

function Collateral_B(loanFinanceItems) {
  const sum_category_one = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category == 9 ? obj.markValue : 0);
  }, 0);
  return sum_category_one;
}

module.exports = {
  // Character
  Character_A,
  Character_B,
  Character_C,
  // Capital
  Capital_A,
  Capital_B,
  Capital_C,
  // Ability_to_pay
  Ability_to_pay_A,
  Ability_to_pay_B,
  // CONDITIONS
  Conditions_A,
  // COLLATERAL
  Collateral_A,
  Collateral_B
};
