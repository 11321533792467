import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { setTargetClient, setTargetLoan } from "../Common/redux/systemLookups";
import { FaFile } from "react-icons/fa";
import LoanStatus from "../Common/LoanStatus";
import { PulseLoader } from "react-spinners";
import axiosInstance from '../axiosInstance'

function OfficerOnProgressLoans() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [t] = useTranslation("global");
  const [loans, setLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getlpscustomers();
  }, []);

  const getlpscustomers = async () => {
    try {
      setIsLoading(true);

      const res_ci = await axiosInstance.get(
        `${serverIP}/loan/loansbyofficer/${data.id}`
      );
      setLoans(res_ci.data);
      setFilteredLoans(res_ci.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-28 flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>Loading ...</div>
        </div>
      </div>
    );
  }

  const redirectPage = async (custId, loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));

      const cusres = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: cusres.data }));

      navigate(`/loanonofficer/${custId}/${loanId}`);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  const redirectEvalPage = async (custId, loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));

      const cusres = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: cusres.data }));

      navigate(`/loanevaluation/${custId}/${loanId}`);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  return (
    <div>
      {/* Modals Start  */}

      {/* Modals End  */}
      <div className="row">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={t("applicant.clientID")}
            onChange={(e) =>
              setFilteredLoans(
                loans.filter((x) =>
                  x.customer.entityExternalId
                    .toString()
                    .includes(e.target.value)
                )
              )
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("applicant.externalID")}</th>
                <th>{t("applicant.fullName")}</th>
                <th>የተፈቀደው የብድር መጠን </th>
                <th>ግምገማ </th>
                <th>Action</th>
                <th>ሁኔታ</th>
                <th>መጠየቅያ ፎርም </th>
                <th>ውሎች</th>
                <th>እግዶች</th>
              </tr>
            </thead>
            <tbody>
              {filteredLoans.map((loan) => {
                return (
                  <>
                    {!loan.auditorapproved && (
                      <tr key={loan.id}>
                        <td>{loan.customer.entityExternalId}</td>
                        <td> {loan.customer.amDisplayName} </td>
                        <td> {loan.approvedPrincipal?.toLocaleString()} </td>
                        <td>
                          <Link
                            className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                            disabled={true}
                            onClick={() =>
                              redirectEvalPage(loan?.customer?.id, loan?.id)
                            }
                          >
                            ግምገማ
                          </Link>
                        </td>

                        <td>
                          {loan.teamleaderchecking ? (
                            " - "
                          ) : (
                            <Link
                              className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                              disabled={true}
                              onClick={() =>
                                redirectPage(loan?.customer?.id, loan?.id)
                              }
                            >
                              ማስታካከያ
                            </Link>
                          )}
                        </td>
                        <td>
                          <LoanStatus loan={loan} />
                        </td>
                        <td>
                          <div className="flex items-center gap-1">
                            <FaFile />
                            <Link
                              className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                              to={`/requestformdoc/${loan?.customer?.id}/${loan?.id}`}
                            >
                              {t("common.request")}
                            </Link>
                          </div>
                        </td>
                        <td>

                          <Link
                            to={`/contracts/${loan?.customer?.id}/${loan?.id}`}
                            className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                          >
                            ውሎች
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/prohibitions/${loan?.customer?.id}/${loan?.id}`}
                            className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                          >
                            እግዶች
                          </Link>
                        </td>
                       
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default OfficerOnProgressLoans;
