import React, { useEffect, useState } from 'react';
import { Accordion, Modal, Badge, Card, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaEye, FaFileAlt, FaPhone, FaUser } from 'react-icons/fa';
import { firstC, secondC, thirdC, fourthC, fifthC, collateralSum } from '../Common/modules/FiveCChecker';
import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';

function LoanEvaluation() {
  const { serverIP } = useSelector(store => store.allsettings);
  const dispatch = useDispatch();
  const { customerId, loanId } = useParams();
  const { targetClient, targetLoan } = useSelector(store => store.systemLookups);

  const [viewObj, setViewObj] = useState({});
  const [show, setShow] = useState(false);
  const [totalMark, settotalMark] = useState(0);

  const [theCustomer, setTheCustomer] = useState([]);
  const [theLoan, setTheLoan] = useState([]);

  const uploadedStyle = 'bg-green-600 text-white flex justify-between';
  const notUploadedStyle = 'flex justify-between';

  useEffect(() => {
    getLoan();
    getCustomer();
  }, []);

  useEffect(() => {
    settotalMark(0);
    settotalMark(
      firstC(targetClient, targetLoan) +
        thirdC(targetClient, targetLoan) +
        fourthC(targetClient, targetLoan) +
        fifthC(targetLoan)
    );
  }, [targetClient, targetLoan]);

  const getLoan = async () => {
    await axiosInstance
      .get(`${serverIP}/loan/loans/${loanId}`)
      .then(res => {
        setTheLoan(res.data);
        dispatch(setTargetLoan({ targetLoan: res.data }));
      })
      .catch(err => {});
  };

  const getCustomer = async () => {
    await axiosInstance
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then(res => {
        setTheCustomer(res.data);
        dispatch(setTargetClient({ client: res.data }));
      })
      .catch(err => {});
  };

  return (
    <div className="row">
      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>

      {/* Modal Show End  */}
      <div className="col-sm-8">
        <Accordion defaultActiveKey="open">
          {/* Condition Evaluation Start  */}
          <Accordion.Item eventKey="open">
            <Accordion.Header className="flex justify-end ">
              <div>
                <Badge>C1</Badge> - Condition Evaluation
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                {targetClient.isMarried ? (
                  <>
                    {targetClient?.marriedgeneralfiles?.map((el, i) => (
                      <ListGroup.Item as="li" className={el.isUploaded ? uploadedStyle : notUploadedStyle}>
                        <div>{el.amDesc}</div>
                        <div className="flex gap-2 items-center">
                          {el.markValue}
                          {el.isUploaded ? (
                            <>
                              {el.fileType === 'application/pdf' ? (
                                <a target="_blank" href={serverIP + el.fileUrl} rel="noopener noreferrer">
                                  <FaFileAlt />
                                </a>
                              ) : (
                                <FaEye
                                  onClick={() => {
                                    setViewObj(el);
                                    setShow(true);
                                    console.log('set show clicked');
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </>
                ) : (
                  <>
                    {targetClient?.singlegeneralfiles?.map((el, i) => (
                      <ListGroup.Item as="li" className={el.isUploaded ? uploadedStyle : notUploadedStyle}>
                        <div>{el.amDesc}</div>
                        <div className="flex gap-2 items-center">
                          {el.markValue}

                          {el.isUploaded ? (
                            <>
                              {el.fileType === 'application/pdf' ? (
                                <a target="_blank" href={serverIP + el.fileUrl} rel="noopener noreferrer">
                                  <FaFileAlt />
                                </a>
                              ) : (
                                <FaEye
                                  onClick={() => {
                                    setViewObj(el);
                                    setShow(true);
                                    console.log('set show clicked');
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </>
                )}

                {targetClient?.customercommonfiles?.map((el, i) => (
                  <ListGroup.Item as="li" className={el.isUploaded ? uploadedStyle : notUploadedStyle}>
                    <div>{el.amDesc}</div>
                    <div className="flex gap-2 items-center">
                      {el.markValue}
                      {el.isUploaded ? (
                        <>
                          {el.fileType === 'application/pdf' ? (
                            <a target="_blank" href={serverIP + el.fileUrl} rel="noopener noreferrer">
                              <FaFileAlt />
                            </a>
                          ) : (
                            <FaEye
                              onClick={() => {
                                setViewObj(el);
                                setShow(true);
                                console.log('set show clicked');
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Condition Evaluation End  */}

          {/* Capital Evaluation Start  */}
          <Accordion.Item eventKey="open">
            <Accordion.Header className="flex justify-end ">
              <div>
                <Badge>C2</Badge> - Capital Evaluation
              </div>
            </Accordion.Header>
            <Accordion.Body></Accordion.Body>
          </Accordion.Item>
          {/* Capital Evaluation End  */}

          {/* Ability To Pay (Capacity) Evaluation Start  */}
          <Accordion.Item eventKey="open">
            <Accordion.Header className="flex justify-end ">
              <div>
                <Badge>C3</Badge> - Ability To Pay (Capacity) Evaluation
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  ወርሃዊ ክፍያ
                  <span className="font-bold">{targetLoan?.totalDueForPeriod?.toLocaleString()}</span>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  ባንክ ስቴትመንት
                  <span className="font-bold">{targetLoan?.bankstateent?.toLocaleString()}</span>
                </ListGroup.Item>

                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  ጠቅላላ ወርሃዊ ቁጠባ
                  <span className="font-bold">{targetClient?.monthlySaving?.toLocaleString()}</span>
                </ListGroup.Item>

                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  ጠቅላላ ዉጤት
                  <span className="font-bold">{thirdC(targetClient, targetLoan)?.toLocaleString()}</span>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Ability To Pay (Capacity) Evaluation End  */}

          {/* Character Evaluation Start  */}
          <Accordion.Item eventKey="open">
            <Accordion.Header className="flex justify-end ">
              <div>
                <Badge>C4</Badge> - Character Evaluation
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  Character Evaluation
                  <span className="font-bold">{targetClient.characterEvaluation}</span>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Character Evaluation End  */}

          {/* Collateral Evaluation Start  */}
          <Accordion.Item eventKey="open">
            <Accordion.Header className="flex justify-end ">
              <div>
                <Badge>C5</Badge> - Collateral Evaluation
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  የተያዘው ንብረት ጠቅላላ ግምት
                  <span className="font-bold">{collateralSum(targetLoan)?.toLocaleString()}</span>
                </ListGroup.Item>

                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  የተፈቀደው የብድር መጠን
                  <span className="font-bold">{targetLoan?.approvedPrincipal?.toLocaleString()}</span>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                  የተያዠው ንብረት ለሚሰጠው ጠቅላላ ብድር (RATIO)
                  <span className="font-bold">{(collateralSum(targetLoan) * 100) / targetLoan.approvedPrincipal}%</span>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Collateral Evaluation End  */}
        </Accordion>

        <div className="flex justify-around mt-2 text-lg bg-gray-400 p-2 rounded-lg">
          <div>C1-{firstC(targetClient, targetLoan)?.toLocaleString()}/35</div>
          <div>C2-{secondC(targetClient, targetLoan)?.toLocaleString()}/10</div>
          <div>C3-{thirdC(targetClient, targetLoan)?.toLocaleString()}/25</div>
          <div>C4-{fourthC(targetClient, targetLoan)?.toLocaleString()}/5 </div>
          <div>C5-{fifthC(targetLoan)?.toLocaleString()}/25</div>
        </div>

        {totalMark < 76 ? (
          <div className="flex justify-around mt-2 text-2xl border-3 border-red-600 p-2 rounded-lg">
            <div className="text-red-700 font-bold">DENIED</div>
            <div className="text-red-700 font-bold">{`Total Score : ${totalMark.toLocaleString()}/100`}</div>
          </div>
        ) : (
          <div className="flex justify-around mt-2 text-2xl border-3 border-green-600 p-2 rounded-lg">
            <div className="text-green-600 font-bold">ACCEPTED</div>
            <div className="text-green-600 font-bold">{`Total Score : ${totalMark.toLocaleString()}/100`}</div>
          </div>
        )}
      </div>
      <div className="col-sm-4">
        <Card>
          <Card.Header>Customer Detail </Card.Header>
          <Card.Body>
            <ListGroup as="ul" style={{ padding: 0 }}>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <FaUser /> {targetClient?.amDisplayName}
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <FaPhone /> {targetClient?.mobileNo}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Header>Loan Detail </Card.Header>
          <Card.Body>
            <ListGroup as="ul" style={{ padding: 0 }}>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div>የብድር አይነት:</div> {targetLoan.loanProductName}
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div> የተፈቀደው የብድር መጠን፡</div> {targetLoan.approvedPrincipal}
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div> ጠቅላላ ቁጠባ፡</div> {targetLoan.r_totalSaving}
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div> ጠቅላላ ሼር፡</div> {targetLoan.r_totalShares}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Header> ያልተሟሉ ብድር የመክፈል አቅም ማሳያ </Card.Header>
          <Card.Body>
            <ListGroup as="ul" style={{ padding: 0 }}>
              {firstC(targetClient, targetLoan) < 35 ? (
                <ListGroup.Item as="li" className="d-flex justify-content-between bg-red-500 align-items-start">
                  C1 : Condition አልተሟላም
                </ListGroup.Item>
              ) : (
                <></>
              )}

              {thirdC(targetClient, targetLoan) < 13 ? (
                <ListGroup.Item as="li" className="d-flex justify-content-between bg-red-500 align-items-start">
                  C3 : የመክፈል አቅሙ ያነሰ ነው፡፡
                </ListGroup.Item>
              ) : (
                <></>
              )}

              {fifthC(targetLoan) < 25 ? (
                <ListGroup.Item as="li" className="justify-content-between bg-red-500 align-items-start">
                  C5 : Collateral አልተሟላም
                  <ul className="pl-10">
                    <li>
                      {`ቀሪ በቤት/በመኪና ዋስትና ሲገመት = ${(
                        Number(targetLoan.approvedPrincipal) - Number(collateralSum(targetLoan))
                      )?.toLocaleString()}`}
                    </li>
                    <li>
                      {`ቀሪ በደመወዝ ዋስትና ሲገመት = ${(
                        ((targetLoan.approvedPrincipal - collateralSum(targetLoan)) * 15) /
                        100
                      )?.toLocaleString()}`}
                    </li>
                  </ul>
                </ListGroup.Item>
              ) : (
                <></>
              )}
            </ListGroup>
          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Header> ግመገማ </Card.Header>
          <Card.Body>
            <ListGroup as="ul" style={{ padding: 0 }}>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                {totalMark > 76 && totalMark < 85 && <>ጥሩ</>}
                {totalMark > 86 && totalMark < 93 && <>በጣም ጥሩ</>}
                {totalMark > 93 && <>እጅግ በጣም ጥሩ</>}
                {totalMark < 76 && <>ደካማ</>}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default LoanEvaluation;
